import logo from './logo.svg';
import './App.css';
import { FullPage, Slide } from 'react-full-page';
import img1 from './1.jpg'
import img2 from './2.jpg'
import img3 from './3.jpg'
import img4 from './4.jpg'
import img5 from './5.jpg'
import img6 from './6.jpg'
import imgLogo from './lg-logo.png'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={imgLogo} alt='imgLogo' className='logo'/>
      </header>
      <div className='main'>
      <img src={img6} alt='img1'/>
        {/* <FullPage controls controlsProps={{className: 'full-page'}}>
          <Slide>
           
          </Slide>
          <Slide>
         
          </Slide>
        </FullPage> */}
      </div>
      <div className='footer'>
        版权所有 (C) 2017 理光(中国)投资有限公司
        <a 
        style={{
          marginLeft:"20px"
        }}
        target='_blank'
        href=' http://www.beian.gov.cn/portal/index.do' >备案编号：沪公网安备 31010102002248号</a>
        <a 
        style={{
          marginLeft:"20px"
        }}
        target='_blank'
        href='https://beian.miit.gov.cn'>沪ICP备08111514号-4</a>
      </div>
     
    </div>
  );
}

export default App;
